import {types} from "../types/types";

const initialState = {
    formOpen: false,
    activo: null,
    refreshList: false
}

export const usuariosReducer = (state = initialState, action) => {
    switch (action.type)
    {
        case types.usuariosOpenForm:
            return {
                ...state,
                formOpen: true
            }

        case types.usuariosCloseForm:
            return {
                ...state,
                formOpen: false
            }

        case types.usuariosSetActive:
            return {
                ...state,
                activo: action.payload
            }

        case types.usuariosClearActive:
            return {
                ...state,
                activo: null
            }

        case types.usuariosRefresh:
            return {
                ...state,
                refreshList: true
            }

        case types.usuariosFinishRefresh:
            return {
                ...state,
                refreshList: false
            }

        default:
            return state;
    }
}