import React, {useEffect} from "react";
import {BrowserRouter as Router, Switch} from "react-router-dom";
import {Layout} from "../layout/Layout";
import {PublicRouter} from "./PublicRouter";
import {PrivateRoute} from "./PrivateRouter";
import {LoginScreen} from "../components/auth/LoginScreen";
import {useDispatch, useSelector} from "react-redux";
import {PageLoader} from "../layout/partials/page-loader";
import {startCheckingToken} from "../actions/auth";

export const AppRouter = () => {
    const dispatch = useDispatch();
    const {checkingToken, uid} = useSelector(state =>  state.auth);
    const isLogged = !!(uid && uid !== '');

    useEffect(() => {
        dispatch(startCheckingToken())
    },[dispatch]);

    if(checkingToken)
    {
        return(<PageLoader />);
    }

    return (
        <Router>
            <div>
                <Router>
                    <div>
                        <Switch>
                            <PublicRouter isLogged={isLogged} exact path="/login" component={LoginScreen} />
                            <PrivateRoute isLogged={isLogged} path="/" component={Layout} />
                        </Switch>
                    </div>
                </Router>
            </div>
        </Router>
    );
}