import moment from "moment";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Modal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  clientesCloseMovs,
  clientesSetActive,
} from "../../../actions/clientes";
import { fetchConToken } from "../../../helpers/fetch";
import { useForm } from "../../../hooks/useForm";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1024px",
    minWidth: "600px",
    maxWidth: "99%",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
};

const movsIni = [];

const initialForm = {
  tipo: "deposito",
  fecha: moment().format("Y-MM-DD"),
  cantidad: "",
};

export const ClientesMovimientos = () => {
  const dispatch = useDispatch();
  const { movsOpen, activo } = useSelector((state) => state.clientes);
  const [movs, setMovs] = useState(movsIni);
  const [formValues, handleInputChange, reset] = useForm(initialForm);
  const { tipo, fecha, cantidad } = formValues;
  const [disabledButtons, setDisabledButtons] = useState(false);

  const handleEliminarMovimiento = ({ id }) => {
    console.log("Eliminando " + id);

    Swal.fire({
      title: "¿Desea eliminar el movimiento?",
      text: "Esta acción no se puede deshacer",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar",
      closeOnConfirm: false,
      closeOnCancel: false,
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken(`clientes/movimientos/${activo}`, { id }, "DELETE")
          .then((response) => response.json())
          .then((body) => {
            if (body.ok) {
              Swal.fire(
                "Eliminado",
                "El movimiento ha sido eliminado correctamente"
              );
              setMovs(body.movs);
            } else {
              Swal.fire("Error", body.msg, "error");
            }
          });
      }
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //setDisabledButtons(true);
    fetchConToken(`clientes/movimientos/${activo}`, formValues, "POST")
      .then((resp) => resp.json())
      .then((body) => {
        setDisabledButtons(false);
        if (body.ok) {
          Swal.fire("Guardado", "Se ha guardado el movimiento", "success");
          setMovs(body.movs);
          reset(initialForm);
        } else {
          Swal.fire("Error", body.msg ? body.msg : body.message, "error");
        }
      });
  };

  const handleCloseModal = () => {
    dispatch(clientesCloseMovs());
    dispatch(clientesSetActive());
  };

  const columnas = [
    { name: "Fecha", selector: "fecha", center: true },
    { name: "Movimiento", selector: "movimiento", center: true },
    { name: "Cantidad", selector: "cantidad", right: true },
    {
      name: "",
      sorteable: false,
      cell: (row) => (
        <>
          <button
            type="button"
            className="btn btn-sm btn-danger"
            disabled={row.movimiento === "Inversion"}
            title="Eliminar movimiento"
            onClick={() => {
              handleEliminarMovimiento(row);
            }}
          >
            <i className="fa fa-trash" />
          </button>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (movsOpen && activo) {
      fetchConToken(`clientes/movimientos/${activo}`)
        .then((resp) => resp.json())
        .then((body) => {
          //console.log(body);
          if (body.ok) {
            setMovs(body.movs);
          }
        });
    }
  }, [movsOpen, activo]);

  return (
    <Modal
      isOpen={movsOpen}
      style={customStyles}
      closeTimeoutMS={0}
      shouldCloseOnEsc={false}
    >
      <h6 className="text-center">Movimientos</h6>
      <hr />
      <form method="post" onSubmit={handleSubmit} autoComplete="off">
        <div className="row">
          <div className="col-md-6">
            <DataTable
              columns={columnas}
              data={movs}
              striped={true}
              dense={true}
            />
          </div>
          <div className="col-md-6">
            Agregar movimiento
            <div className="form-group mt-2">
              <label>Tipo *</label>
              <select
                name="tipo"
                className="form-control form-control-sm"
                value={tipo}
                onChange={handleInputChange}
                required={true}
              >
                <option value="deposito">Deposito</option>
                <option value="retiro">Retiro</option>
              </select>
            </div>
            <div className="form-group mt-2">
              <label>Fecha *</label>
              <input
                type="date"
                name="fecha"
                className="form-control form-control-sm"
                value={fecha}
                onChange={handleInputChange}
                required={true}
              />
            </div>
            <div className="form-group mt-2">
              <label>Cantidad *</label>
              <input
                name="cantidad"
                type="type"
                className="form-control form-control-sm text-right"
                value={cantidad}
                onChange={handleInputChange}
                required={true}
              />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <small>(*) Campos requeridos</small>
          </div>
          <div className="col-md-6 text-right mt-2">
            <button
              type="submit"
              className={`btn btn-success ${
                disabledButtons ? "spinner spinner-white spinner-right" : ""
              }`}
              disabled={disabledButtons}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-default ml-1"
              onClick={handleCloseModal}
              disabled={disabledButtons}
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </Modal>
  );
};
