import { types } from "../types/types";

export const clientesOpenForm = () => ({ type: types.clientesOpenForm });
export const clientesCloseForm = () => ({ type: types.clientesCloseForm });

export const clientesOpenDetail = () => ({ type: types.clientesOpenDetail });
export const clientesCloseDetail = () => ({ type: types.clientesCloseDetail });

export const clientesOpenMovs = () => ({ type: types.clientesOpenMovs });
export const clientesCloseMovs = () => ({ type: types.clientesCloseMovs });

export const clientesSetActive = (cid) => ({
  type: types.clientesSetActive,
  payload: cid,
});

export const clientesClearActive = () => ({
  type: types.clientesClearActive,
});

export const clientesRefresh = () => ({
  type: types.clientesRefresh,
});

export const clientesFinishRefresh = () => ({
  type: types.clientesFinishRefresh,
});
