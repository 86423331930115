import { types } from "../types/types";

const initialState = {
  formOpen: false,
  activo: null,
  refreshList: false,
  detailOpen: false,
  movsOpen: false,
};

export const clientesReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.clientesOpenForm:
      return {
        ...state,
        formOpen: true,
      };

    case types.clientesCloseForm:
      return {
        ...state,
        formOpen: false,
      };

    case types.clientesSetActive:
      return {
        ...state,
        activo: action.payload,
      };

    case types.clientesClearActive:
      return {
        ...state,
        activo: null,
      };

    case types.clientesRefresh:
      return {
        ...state,
        refreshList: true,
      };

    case types.clientesFinishRefresh:
      return {
        ...state,
        refreshList: false,
      };

    case types.clientesOpenDetail:
      return {
        ...state,
        detailOpen: true,
      };

    case types.clientesCloseDetail:
      return {
        ...state,
        detailOpen: false,
      };

    case types.clientesOpenMovs:
      return {
        ...state,
        movsOpen: true,
      };

    case types.clientesCloseMovs:
      return {
        ...state,
        movsOpen: false,
      };

    default:
      return state;
  }
};
