import {fetchConToken, fetchSinToken} from "../helpers/fetch";
import Swal from "sweetalert2";
import {types} from "../types/types";

export const startLogin = (email, password) => {
    return async (dispatch) => {
        const resp = await fetchSinToken('login', {email, password}, 'POST');
        const body = await resp.json();

        if(body.ok)
        {
            localStorage.setItem('token', body.token);
            dispatch(login({uid: body.uid, name:body.name, type:body.type}));
        } else {
            Swal.fire('Error', body.msg, 'error');
        }

        dispatch(endChecking());


    }
}

export const startChecking = () => ({
   type: types.authStartChecking
});

export const endChecking = () => ({
    type: types.authEndChecking
});


export const startCheckingToken = () => {
    return async (dispatch) => {
        const resp = await fetchConToken('renew', {});
        const body = await resp.json();
        if(body.ok){
            dispatch(login({uid: body.uid, name:body.name, type:body.type}))
        }

        dispatch(endCheckingToken());
    }
};

export const endCheckingToken = () => ({
    type: types.authEndCheckingToken
});


const login = (user) => ({
    type: types.authLogin,
    payload: user
});

export const startLogout = () => {
    return async () =>{
        await fetchConToken('logout', {});
    }
}

export const logout = () => ({
    type: types.authLogout
});