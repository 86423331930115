import React from "react";
import {User} from "./extras/dropdown/user";
import {useSelector} from "react-redux";

export const Header = () => {

    const {name} = useSelector(state => state.auth);

    return (
        <div id="kt_header" className="header header-fixed">
            <div className="container-fluid d-flex align-items-stretch justify-content-between">
                <div/>
                <div className="topbar">

                    <div className="dropdown">

                        <div className="topbar-item" data-toggle="dropdown" data-offset="0px,0px">
                            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
                                <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">Bienvenido,</span>
                                <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">{name}</span>
                                <span className="symbol symbol-35 symbol-light-success">
												<span className="symbol-label font-size-h5 font-weight-bold">{name.substring(0,1)}</span>
											</span>
                            </div>
                        </div>


                        <div className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg p-0">

                            <User/>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    );
}