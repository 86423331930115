import React from "react";
import {PageLoader} from "./partials/page-loader";
import {HeaderMobile} from "./partials/header-mobil";
import {Aside} from "./partials/aside";
import {Header} from "./partials/header";
import {Footer} from "./partials/footer";
import {Route, Switch} from "react-router-dom";
import {DashboardAdmin} from "../components/admin/dashboard/DashboardAdmin";
import {ClientesScreen} from "../components/admin/clientes/ClientesScreen";
import {UsuariosScreen} from "../components/admin/usuarios/UsuariosScreen";
import {OperacionesScreen} from "../components/admin/operaciones/OperacionesScreen";
import {useSelector} from "react-redux";
import {AsideCliente} from "./partials/aside_cliente";
import {DashboardClientes} from "../components/clientes/DashboardClientes";
import {ReportesScreen} from "../components/admin/reportes/ReportesScreen";
import {MiCuenta} from "../components/clientes/MiCuenta";

export const Layout = () => {

    const {type} = useSelector(state => state.auth);


    return (
        <div>
            <PageLoader/>
            <HeaderMobile/>
            <div className="d-flex flex-column flex-root" style={{height: '100%'}}>
                <div className="d-flex flex-row flex-column-fluid page">

                    {
                        type.toString() === "1"
                            ?
                            <Aside/>
                            : <AsideCliente/>
                    }


                    <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                        <Header/>
                        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">

                            <Switch>
                                <Route exact path="/" component={type.toString() === "1" ? DashboardAdmin : DashboardClientes}/>
                                <Route exact path="/clientes" component={ClientesScreen}/>
                                <Route exact path="/usuarios" component={UsuariosScreen}/>
                                <Route exact path="/operaciones" component={OperacionesScreen}/>
                                <Route exact path="/reportes" component={ReportesScreen}/>

                                <Route exact path="/cuenta" component={MiCuenta}/>
                            </Switch>

                        </div>
                        <Footer/>
                    </div>

                </div>
            </div>
        </div>
    );
}