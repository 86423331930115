import {types} from "../types/types";

const initialState = {
    checking: false,
    checkingToken: true
}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {

        case types.authLogin:
            return {
                ...state,
                uid: action.payload.uid,
                name: action.payload.name,
                type: action.payload.type
            }

        case types.authStartChecking:
            return {
                ...state,
                checking: true
            }

        case types.authEndChecking:
            return {
                ...state,
                checking: false
            }

        case types.authStartCheckingToken:
            return {
                ...state,
                checkingToken: true
            }

        case types.authEndCheckingToken:
            return {
                ...state,
                checkingToken: false
            }

        case types.authLogout:
            return {
                checkingToken: false
            }

        default:
            return state;
    }
}