import React, {useEffect, useState} from "react"
import Modal from "react-modal"
import {useDispatch, useSelector} from "react-redux"
import DatePiker from "react-date-picker"
import {
  clientesClearActive,
  clientesCloseForm,
  clientesRefresh,
} from "../../../actions/clientes"
import {fetchConToken, fetchConTokenAndFile} from "../../../helpers/fetch"
import {useForm} from "../../../hooks/useForm"
import moment from "moment"
import Swal from "sweetalert2"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    minWidth: "400px",
    maxWidth: "99%",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
}

const initialForm = {
  nombre: "",
  rfc: "",
  direccion: "",
  telefono: "",
  contrato: "",
  inversion: "",
  banco: "",
  isActivo: 1,
  correo: "",
  password: "",
}

Modal.setAppElement("#root")

export const ClientesForma = () => {
  const dispatch = useDispatch()
  const [formValues, handleInputChange, handleInputChangeManual] =
    useForm(initialForm)
  const {
    nombre,
    rfc,
    direccion,
    telefono,
    contrato,
    inversion,
    banco,
    isActivo,
    correo,
    password,
  } = formValues

  const {formOpen, activo} = useSelector((state) => state.clientes)
  const [fechaInicio, setFechaInicio] = useState(moment().toDate())
  const [disabledButtons, setDisabledButtons] = useState(false)
  const [labelPdf, setLabelPdf] = useState("Seleccionar")
  const [inputFileSelected, setInputFileSelected] = useState(null)
  const [contratoPdf, setContratoPdf] = useState("")

  const handleCloseModal = () => {
    dispatch(clientesCloseForm())
    dispatch(clientesClearActive())
  }

  const handleChangeFecha = (e) => {
    if (e) {
      setFechaInicio(moment(e).toDate())
    } else {
      setFechaInicio("")
    }
  }

  const handleChangePdf = (e) => {
    setLabelPdf(e.target.value)
    setInputFileSelected(e.target.files[0])
  }

  const handleBtnDescargar = () => {
    fetchConToken(`clientes/contrato/${activo}`)
      .then((result) => result.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement("a")
        link.href = url
        link.setAttribute("download", `contrato_${activo}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setDisabledButtons(true)

    let ruta = activo ? `clientes/guardar/${activo}` : "clientes/guardar"

    const formData = new FormData()

    formData.append("nombre", formValues.nombre)
    formData.append("rfc", formValues.rfc)
    formData.append("direccion", formValues.direccion)
    formData.append("telefono", formValues.telefono)
    formData.append("contrato", formValues.contrato)
    formData.append("inversion", formValues.inversion)
    formData.append("banco", formValues.banco)
    formData.append("isActivo", formValues.isActivo)
    formData.append("correo", formValues.correo)
    formData.append("password", formValues.password)
    formData.append("contratoPdf", inputFileSelected)
    formData.append("fechaInicio", moment(fechaInicio).format("YYYY-MM-DD"))

    fetchConTokenAndFile(ruta, formData, "POST")
      .then((resp) => resp.json())
      .then((body) => {
        setDisabledButtons(false)
        if (body.ok) {
          Swal.fire("Guardado", "Se ha guardado el cliente", "success")
          dispatch(clientesCloseForm())
          dispatch(clientesRefresh())
          dispatch(clientesClearActive())
        } else {
          Swal.fire("Error", body.msg, "error")
        }
      })
  }

  useEffect(() => {
    if (formOpen && activo) {
      fetchConToken(`clientes/${activo}`)
        .then((resp) => resp.json())
        .then((body) => {
          if (body.ok) {
            handleInputChangeManual({
              nombre: body.cliente.nombre,
              rfc: body.cliente.rfc,
              direccion: body.cliente.direccion,
              telefono: body.cliente.telefono,
              contrato: body.cliente.contrato,
              inversion: body.cliente.inversion,
              banco: body.cliente.banco,
              isActivo: body.cliente.activo,
              correo: body.cliente.email,
              password: "",
            })

            setContratoPdf(body.contrato)

            setFechaInicio(
              body.cliente.fecha_inicio && body.cliente.fecha_inicio.length > 0
                ? moment(body.cliente.fecha_inicio).toDate()
                : ""
            )
          }
        })
    } else {
      handleInputChangeManual(initialForm)
    }
  }, [activo, formOpen])

  return (
    <Modal
      isOpen={formOpen}
      style={customStyles}
      closeTimeoutMS={0}
      shouldCloseOnEsc={false}
    >
      <h6>Cliente</h6>
      <hr />
      <form method="post" onSubmit={handleSubmit} autoComplete="off">
        <div className="row modalMaxHeight">
          <div className="col-md-8">
            <div className="form-group">
              <label htmlFor="nombre">Nombre *</label>
              <input
                type="text"
                name="nombre"
                value={nombre}
                onChange={handleInputChange}
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="rfc">RFC</label>
              <input
                type="text"
                name="rfc"
                value={rfc}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="direccion">Dirección</label>
              <textarea
                name="direccion"
                value={direccion}
                onChange={handleInputChange}
                className="form-control"
                rows="3"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="telefono">Teléfono</label>
              <input
                type="text"
                name="telefono"
                value={telefono}
                maxLength={20}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="contrato">No. Contrato</label>
              <input
                type="text"
                name="contrato"
                value={contrato}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="inversion">Monto de Inversión</label>
              <input
                type="text"
                name="inversion"
                value={inversion}
                onChange={handleInputChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="col-md-6">
            {activo ? (
              <div className="form-group">
                <label>Contrato en PDF</label>
                <div></div>
                <div className="custom-file">
                  <input
                    type="file"
                    className="custom-file-input"
                    id="contrato_pdf"
                    onChange={handleChangePdf}
                    accept="application/pdf"
                  />
                  <label
                    className="custom-file-label"
                    htmlFor="contrato_pdf"
                    id="contrato_pdf_label"
                  >
                    {labelPdf}
                  </label>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          {contratoPdf != "" ? (
            <div className="col-md-6">
              {activo ? (
                <button
                  style={{marginTop: "25px"}}
                  className="btn btn-light-danger"
                  type="button"
                  onClick={handleBtnDescargar}
                >
                  <i className="fa fa-file-pdf" /> Descargar{" "}
                </button>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          <div className="col-md-12">
            <div className="form-group">
              <label htmlFor="banco">Datos bancarios</label>
              <textarea
                name="banco"
                value={banco}
                onChange={handleInputChange}
                className="form-control"
                rows="5"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="fechaInicio">Inicio de operaciones</label>
              <DatePiker
                value={fechaInicio}
                onChange={handleChangeFecha}
                name="fechaInicio"
                className="form-control"
              />
            </div>
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="isActivo">Activo</label>
              <select
                name="isActivo"
                value={isActivo}
                onChange={handleInputChange}
                className="form-control"
              >
                <option value="1">Si</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <div className="col-md-12">
            <h6>Datos de acceso al portal</h6>
            <hr />
          </div>
          <div className="col-md-6">
            <div className="form-group">
              <label htmlFor="correo">Correo *</label>
              <input
                type="email"
                value={correo}
                onChange={handleInputChange}
                name="correo"
                className="form-control"
                required={true}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group">
              <label htmlFor="password">Contraseña {!activo && "*"}</label>
              <input
                type="password"
                value={password}
                onChange={handleInputChange}
                name="password"
                className="form-control"
                required={!activo}
              />
              {activo && (
                <small>Dejar en blanco si no desea cambiar la contraseña</small>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <small>(*) Campos requeridos</small>
            {!activo ? (
              <small>
                <br />
                Debe guardar el cliente antes de subir su contrato en PDF
              </small>
            ) : (
              ""
            )}
          </div>
          <div className="col-md-6 text-right mt-2">
            <button
              type="submit"
              className={`btn btn-success ${
                disabledButtons ? "spinner spinner-white spinner-right" : ""
              }`}
              disabled={disabledButtons}
            >
              Guardar
            </button>
            <button
              type="button"
              className="btn btn-default ml-1"
              onClick={handleCloseModal}
              disabled={disabledButtons}
            >
              Cancelar
            </button>
          </div>
        </div>
      </form>
    </Modal>
  )
}
