const baseUrl = process.env.REACT_APP_API_URL;

const fetchSinToken = (endpoint, data, method = 'GET') => {
    const url = `${baseUrl}/${endpoint}`;
    if (method === 'GET') {
        return fetch(url);
    } else {
        return fetch(url,{
            method,
            headers:{
                'Content-type':'application/json',
            },
            body: JSON.stringify(data)
        })
    }
}

const fetchConToken = (endpoint, data, method = 'GET') => {
    const token = localStorage.getItem('token') || '';
    const url = `${baseUrl}/${endpoint}`;
    if (method === 'GET') {
        return fetch(url, {
            method, headers:{
                'Authorization':`Bearer ${token}`,

            }
        });
    } else {
        return fetch(url,{
            method,
            headers:{
                'Content-type':'application/json',
                'Accept': 'application/json',
                'Authorization':`Bearer ${token}`
            },
            body: JSON.stringify(data)
        })
    }
}

const fetchConTokenAndFile = (endpoint, data, method = 'GET') => {
    const token = localStorage.getItem('token') || '';
    const url = `${baseUrl}/${endpoint}`;
    if (method === 'GET') {
        return fetch(url, {
            method, headers:{
                'Authorization':`Bearer ${token}`,
            }
        });
    } else {
        return fetch(url,{
            method,
            headers:{
                'Authorization':`Bearer ${token}`,
            },
            body: data,
        })
    }
}

export {
    fetchSinToken,
    fetchConToken,
    fetchConTokenAndFile
}