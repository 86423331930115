import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import {useDispatch, useSelector} from "react-redux";
import DataTable from "react-data-table-component";
import {fetchConToken} from "../../../helpers/fetch";
import {operacionesClearActive, operacionesCloseDetalle, operacionesRefresh} from "../../../actions/operaciones";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '99%',
        minWidth: '400px',
        maxWidth: '99%',
        minHeight: '500px',
        height: '700px'
    },
    overlay: {
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    }
};

Modal.setAppElement('#root');

export const OperacionesDetalle = () => {

    const dispatch = useDispatch();
    const {formOpenDetalle, activo} = useSelector(state => state.operaciones);
    const [detalles, setDetalles] = useState([]);
    const [columnas, setColumnas] = useState([]);
    const [info, setInfo] = useState(null);

    const handleBtnCerrar = () => {
        dispatch(operacionesClearActive());
        dispatch(operacionesCloseDetalle());
    }

    const handleBtnAprobar = () => {
        Swal.fire({
            title: '¿Desea aprobar las operaciones de este archivo?',
            text: 'Una vez confirmadas las operaciones se reflejarán en los movimientos de los clientes',
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#1BC5BD",
            confirmButtonText: "Si, aprobar!",
            cancelButtonText: "No, cancelar",
            closeOnConfirm: false,
            closeOnCancel: false,
            icon: 'warning'
        }).then((result) => {
            if (result.isConfirmed) {

                fetchConToken(`operaciones/aprobar/${activo}`)
                    .then(response => response.json())
                    .then(body => {
                        if (body.ok) {
                            Swal.fire('Aprobado', 'Se ha aprobado con exito, ahora los movimientos se reflejarán en los clientes');
                            dispatch(operacionesRefresh());
                            fetchConToken(`operaciones/${activo}`)
                                .then(result => result.json())
                                .then(body => {
                                    if (body.ok) {
                                        //setDetalles(body.data);
                                        //setColumnas(body.columns)
                                        //setInfo(body.info);
                                        handleBtnCerrar();
                                    } else {
                                        Swal.fire('Error', body.msg, 'error');
                                    }
                                });
                        } else {
                            Swal.fire('Error', body.msg, 'error');
                        }
                    });

            }
        })
    }

    const handleBtnDescargar = () => {
        if(info?.descargar !== ''){
            fetchConToken(`operaciones/descargar/${activo}`)
                .then(result => result.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', info.archivo);
                    document.body.appendChild(link);
                    link.click();
                    link.parentNode.removeChild(link);
                });
        }
    }

    useEffect(() => {
        if (activo) {
            fetchConToken(`operaciones/${activo}`)
                .then(result => result.json())
                .then(body => {
                    if (body.ok) {
                        setDetalles(body.data);
                        setColumnas(body.columns)
                        setInfo(body.info);
                    } else {
                        Swal.fire('Error', body.msg, 'error');
                    }
                });
        }
    }, [activo])

    return (
        <Modal
            isOpen={formOpenDetalle}
            style={customStyles}
            closeTimeoutMS={0}
            shouldCloseOnEsc={false}
        >
            <h6>Operaciónes {info?.fecha !== '' ? `(Fecha: ${info?.fecha})` : ''}</h6>
            <hr/>
            <div className=" ">

                <div className="row">
                    <div className="col-md-12">
                        <br/>
                        <DataTable
                            columns={columnas}
                            data={detalles}
                            striped={true}
                            highlightOnHover={true}
                            dense={true}
                            fixedHeader={true}
                            fixedHeaderScrollHeight={"490px"}
                            pagination={false}
                        />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-md-12 text-right">
                        {
                            info?.status.toString() === "0" ?
                                <button type="button" className="btn btn-primary" onClick={handleBtnAprobar}><i className="fa fa-check-circle"/> Aprobar</button>
                                : ''
                        }
                        {
                            info?.descargar !== '' ?
                                <button type="button" className="btn btn-secondary ml-3" onClick={handleBtnDescargar}><i className="fa fa-download"/> Descargar</button>
                                : ''
                        }
                        <button type="button" className="btn btn-default ml-3" onClick={handleBtnCerrar}><i className="fa fa-sign-out-alt"/> Cerrar</button>
                    </div>
                </div>

            </div>
        </Modal>
    )
}