import React, { useEffect, useState } from "react"
import Modal from "react-modal"
import { useDispatch, useSelector } from "react-redux"
import ReactApexChart from "react-apexcharts"
import DataTable from "react-data-table-component"
import { fetchConToken } from "../../../helpers/fetch"
import {
  clientesClearActive,
  clientesCloseDetail,
} from "../../../actions/clientes"
import moment from "moment"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "1024px",
    minWidth: "600px",
    maxWidth: "99%",
  },
  overlay: {
    background: "rgba(0,0,0,0.5)",
    zIndex: 1000,
  },
}

const numberFormat = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
})

const columnas = [
  { name: "Año", selector: "anio" },
  { name: "Mes", selector: "mesLabel" },
  { name: "Gananacia", selector: "ganancia", right: true },
  { name: "%", selector: "porcentaje", right: true },
]

const columnasAcumulados = [
  {
    name: "Año",
    selector: "year",
    cell: (row) => {
      console.log(row)
      if (row.year == "Total") {
        return (
          <div>
            <b>{row.year}</b>
          </div>
        )
      }
      return <div>{row.year}</div>
    },
  },
  {
    name: "Ganancia",
    selector: "ganancia_label",
    right: true,
    cell: (row) => {
      if (row.year == "Total") {
        return (
          <div>
            <b>{row.ganancia_label}</b>
          </div>
        )
      }
      return <div>{row.ganancia_label}</div>
    },
  },
]

const seriesInit = []
const optionsInit = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [],
  },

  yaxis: {
    labels: {
      formatter: function (val, index) {
        return numberFormat.format(val)
      },
    },
  },
  tooltip: {
    custom: ({ series, w, dataPointIndex }) => {
      //console.log(w);
      const options2 = { style: "currency", currency: "USD" }
      const numberFormat2 = new Intl.NumberFormat("en-US", options2)

      let html =
        '<div class="apexcharts-tooltip-title" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">' +
        w.globals.categoryLabels[dataPointIndex] +
        "</div>\n" +
        '<div class="apexcharts-tooltip-series-group apexcharts-active" style="order: 1; display: flex;">\n' +
        '    <span class="apexcharts-tooltip-marker" style="background-color: rgb(0, 143, 251);"></span>\n' +
        '    <div class="apexcharts-tooltip-text" style="font-family: Helvetica, Arial, sans-serif; font-size: 12px;">\n' +
        '        <div class="apexcharts-tooltip-y-group">\n' +
        '            <span class="apexcharts-tooltip-text-y-label">Ganancia: </span>\n' +
        '            <span class="apexcharts-tooltip-text-y-value">' +
        numberFormat2.format(series[0][dataPointIndex]) +
        "</span>\n" +
        "        </div>\n" +
        '        <div class="apexcharts-tooltip-y-group">\n' +
        '            <span class="apexcharts-tooltip-text-y-label">Porcentaje: </span>\n' +
        '            <span class="apexcharts-tooltip-text-y-value">' +
        w.config.series[0].name[dataPointIndex] +
        " %</span>\n" +
        "        </div>\n" +
        '        <div class="apexcharts-tooltip-goals-group">\n' +
        '            <span class="apexcharts-tooltip-text-goals-label"></span>\n' +
        '            <span class="apexcharts-tooltip-text-goals-value"></span>\n' +
        "        </div>\n" +
        '        <div class="apexcharts-tooltip-z-group">\n' +
        '            <span class="apexcharts-tooltip-text-z-label"></span>\n' +
        '            <span class="apexcharts-tooltip-text-z-value"></span>\n' +
        "        </div>\n" +
        "    </div>\n" +
        "</div>"

      return html
    },
  },
}

Modal.setAppElement("#root")

export const ClientesDetalle = () => {
  const dispatch = useDispatch()

  const { detailOpen, activo } = useSelector((state) => state.clientes)
  const [cliente, setCliente] = useState("")
  const [inversion, setInversion] = useState("")
  const [operaciones, setOperaciones] = useState([])
  const [series, setSeries] = useState(seriesInit)
  const [options, setOptions] = useState(optionsInit)
  const [ganancia, setGanancia] = useState("")
  const [total, setTotal] = useState("")
  const [porcentaje, setPorcentaje] = useState("")
  const [porcentajePromedio, setporcentajePromedio] = useState("")
  const [gananciaPromedio, setgananciaPromedio] = useState("")
  const [year, setYear] = useState(moment().format("Y"))
  const [years, setYears] = useState([year])
  const [movimientos, setMovimientos] = useState([])
  const [acumulados, setAcumulados] = useState([])
  const [visibleAcumulados, setVisibleAcumulados] = useState(false)
  const [visibleTotales, setVisibleTotales] = useState(true)

  const handleCloseModal = () => {
    setSeries([])
    setOptions({
      ...options,
      xaxis: {
        categories: [],
      },
    })

    dispatch(clientesCloseDetail())
    dispatch(clientesClearActive())
  }

  const handleClickChangeYear = (year) => {
    setVisibleAcumulados(false)
    setVisibleTotales(true)
    setYear(year)
    fetchConToken(`clientes/detalle/${activo}`, { year }, "POST")
      .then((resp) => resp.json())
      .then((body) => {
        if (body.ok) {
          setCliente(body.cliente.nombre)
          setInversion(body.cliente.inversion)
          setOperaciones(body.operaciones)
          setSeries(body.series)
          setGanancia(body.ganancia)
          setTotal(body.total)
          setPorcentaje(body.porcentaje)
          setgananciaPromedio(body.ganancia_promedio)
          setporcentajePromedio(body.porcentaje_promedio)
          setMovimientos(body.cliente.movimientos)
          setAcumulados(body.gananciaGlobal)
          setOptions({
            ...options,
            series: body.series,
            xaxis: {
              ...options.xaxis,
              categories: body.categorias,
            },
          })
          setYears(body.years)
        }
      })
  }

  const handleClickAcumulados = () => {
    setVisibleAcumulados(true)
    setVisibleTotales(false)
  }

  useEffect(() => {
    if (detailOpen && activo) {
      fetchConToken(`clientes/detalle/${activo}`, { year }, "POST")
        .then((resp) => resp.json())
        .then((body) => {
          if (body.ok) {
            setYear(body.year)
            setCliente(body.cliente.nombre)
            setInversion(body.cliente.inversion)
            setOperaciones(body.operaciones)
            setSeries(body.series)
            setGanancia(body.ganancia)
            setTotal(body.total)
            setPorcentaje(body.porcentaje)
            setgananciaPromedio(body.ganancia_promedio)
            setporcentajePromedio(body.porcentaje_promedio)
            setMovimientos(body.cliente.movimientos)
            setAcumulados(body.gananciaGlobal)
            setOptions({
              ...options,
              series: body.series,
              xaxis: {
                ...options.xaxis,
                categories: body.categorias,
              },
            })
            setYears(body.years)
          }
        })
    }
  }, [detailOpen])

  return (
    <Modal
      isOpen={detailOpen}
      style={customStyles}
      closeTimeoutMS={0}
      shouldCloseOnEsc={false}
    >
      <div className="row">
        <div className="col-md-12">
          <table className="table table-sd table-bordered">
            <tbody>
              <tr>
                <th rowSpan={2}>Cliente</th>
                <td rowSpan={2}>{cliente}</td>
                <th>Ganancia acomulada</th>
                <td className="text-center">{ganancia}</td>
                <th
                  rowSpan={4}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  Total
                </th>
                <td
                  rowSpan={4}
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  {total}
                </td>
              </tr>
              <tr>
                <th>% Ganancia</th>
                <td className="text-center">{porcentaje}</td>
              </tr>
              <tr>
                <th rowSpan={2}>Movimientos</th>
                <td rowSpan={2} className="text-center">
                  <table className="table table-sm table-borderless">
                    <thead>
                      <tr>
                        <th>Fecha</th>
                        <th>Mov.</th>
                        <th>Cant.</th>
                      </tr>
                    </thead>
                    <tbody>
                      {movimientos.map((value, index) => {
                        return (
                          <tr key={index}>
                            <td className="text-center">{value.fecha}</td>
                            <td className="text-center">{value.tipo}</td>
                            <td className="text-right">{value.cantidad}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </td>
                <th>Ganancia promedio</th>
                <td className="text-center">{gananciaPromedio}</td>
              </tr>
              <tr>
                <th>Porcentaje promedio</th>
                <td className="text-center">{porcentajePromedio}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="col-md-12"></div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center">
          {years.map((value, index) => {
            return (
              <button
                className={`btn ${value.toString() == year.toString()
                  ? "btn-primary"
                  : "btn-default"
                  } mr-2`}
                type="button"
                key={value}
                onClick={() => {
                  handleClickChangeYear(value)
                }}
              >
                {value}
              </button>
            )
          })}

          <button
            className="btn btn-success"
            type="button"
            onClick={() => handleClickAcumulados()}
          >
            Acomulados
          </button>
        </div>
        {visibleAcumulados && (
          <div className="col-md-6">
            <DataTable
              columns={columnasAcumulados}
              data={acumulados}
              striped={true}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={"350px"}
            />
          </div>
        )}
        {visibleTotales && (
          <div className="col-md-6">
            <DataTable
              columns={columnas}
              data={operaciones}
              striped={true}
              dense={true}
              fixedHeader={true}
              fixedHeaderScrollHeight={"350px"}
            />
          </div>
        )}
        <div className="col-md-6">
          <div id="chart">
            {activo && (
              <ReactApexChart
                options={options}
                series={series}
                type="line"
                height={350}
                operaciones={operaciones}
              />
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-right mt-4">
          <button
            type="button"
            className="btn btn-default ml-1"
            onClick={handleCloseModal}
          >
            Cerrar
          </button>
        </div>
      </div>
    </Modal>
  )
}
