import React, {useEffect, useState} from "react";
import {SubHeader} from "../../layout/partials/subheader/subheader-v1";
import {fetchConToken} from "../../helpers/fetch";

export const MiCuenta = () => {

    const [info, setInfo] = useState(null)

    useEffect(() => {
       fetchConToken('dashboard/info', {}, 'POST')
           .then(resp => resp.json())
           .then(body => {
                if(body.ok) {
                    setInfo(body.info)
                }
           })
    }, []);

    if (!info) {
        return (<>
            <SubHeader title={"Dashboard"}/>
            <div className="d-flex flex-column-fluid">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    return (<>
        <SubHeader title={"Mi cuenta"}/>
        <div className="d-flex flex-column-fluid">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8">
                        <div className="card card-custom gutter-b">
                            <div className="card-body">
                                <table className="table table-striped table-sm table-bordered">
                                    <tbody>
                                    <tr>
                                        <td colSpan="3" className="text-center">Nombre</td>
                                        <td className="text-center">RFC</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="3" className="text-center">{ info.nombre }</td>
                                        <td className="text-center">{ info.rfc }</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-center">Dirección</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="min-h-150px h-150px">{ info.direccion }</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="text-center">Correo</td>
                                        <td colSpan="2" className="text-center">Teléfono</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2" className="text-center">{ info.email }</td>
                                        <td colSpan="2" className="text-center">{ info.telefono }</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-center">Contrato</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-center">{ info.contrato }</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="text-center">Datos Bancarios</td>
                                    </tr>
                                    <tr>
                                        <td colSpan="4" className="min-h-150px h-150px">{ info.banco }</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}