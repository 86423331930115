import React, { useEffect, useState } from "react"
import DataTable from "react-data-table-component"
import Swal from "sweetalert2"
import { useDispatch, useSelector } from "react-redux"
import { saveAs } from "file-saver"
import { SubHeader } from "../../../layout/partials/subheader/subheader-v1"
import { useForm } from "../../../hooks/useForm"
import { fetchConToken } from "../../../helpers/fetch"
import { ClientesForma } from "./ClientesForma"
import {
  clientesClearActive,
  clientesFinishRefresh,
  clientesOpenDetail,
  clientesOpenForm,
  clientesOpenMovs,
  clientesRefresh,
  clientesSetActive,
} from "../../../actions/clientes"
import { ClientesDetalle } from "./ClientesDetalle"
import { ClientesMovimientos } from "./ClientesMovimientos"
import { CustomTable } from "../../customs/CustomTable"

const initFilters = {
  filtro: "",
}

const intialSize = 20

const defaultPaginationOptions = {
  rowsPerPageText: "Registros por página:",
  rangeSeparatorText: "de",
}

export const ClientesScreen = () => {
  const dispatch = useDispatch()
  const { refreshList } = useSelector((state) => state.clientes)

  const [filtersValue, setFiltersValue] = useForm(initFilters)
  const { filtro } = filtersValue
  const [loading, setLoading] = useState(false)
  const [clientes, setClientes] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [size, setSize] = useState(intialSize)

  const handleVerCliente = ({ cid }) => {
    dispatch(clientesSetActive(cid))
    dispatch(clientesOpenDetail())
  }

  const handleNuevoCliente = () => {
    dispatch(clientesClearActive())
    dispatch(clientesOpenForm())
  }

  const handleEditarCliente = ({ cid }) => {
    dispatch(clientesSetActive(cid))
    dispatch(clientesOpenForm())
  }

  const handleMovsCliente = ({ cid }) => {
    dispatch(clientesSetActive(cid))
    dispatch(clientesOpenMovs())
  }

  const handleEliminarCliente = ({ cid }) => {
    Swal.fire({
      title: "¿Desea eliminar el ciente?",
      text: "Esta acción no se puede deshacer",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar",
      closeOnConfirm: false,
      closeOnCancel: false,
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken(`clientes/${cid}`, {}, "DELETE")
          .then((response) => response.json())
          .then((body) => {
            if (body.ok) {
              Swal.fire(
                "Eliminado",
                "El cliente ha sido eliminado correctamente"
              )
              dispatch(clientesRefresh())
            } else {
              Swal.fire("Error", body.msg, "error")
            }
          })
      }
    })
  }

  const handleExportarClientes = () => {
    fetchConToken("clientes/excel/descarga", { filtro }, "POST")
      .then((response) => response.json())
      .then((body) => {
        if (body.ok) {
          saveAs(body.file, "Clientes.xlsx")
        } else {
          Swal.fire("Error", body.msg, "error")
        }
      })
  }

  const columns = [
    { name: "No. Cliente", selector: "id", minWidth: "50px" },
    { name: "Cliente", selector: "nombre", minWidth: "300px" },
    { name: "Correo", selector: "email", minWidth: "300px" },
    { name: "Contrato", selector: "contrato" },
    { name: "Inversión", selector: "inversion", right: true },
    {
      name: "Activo",
      selector: "activoLabel",
      center: true
    },
    {
      name: "Acciones",
      sorteable: false,
      cell: (row) => (
        <>
          <button
            type="button"
            onClick={() => {
              handleVerCliente(row)
            }}
            className="btn btn-sm btn-default ml-1"
            title="Ver detalles"
          >
            <i className="fa fa-search" />
          </button>
          <button
            type="button"
            onClick={() => {
              handleEditarCliente(row)
            }}
            className="btn btn-sm btn-primary ml-1"
            title="Editar"
          >
            <i className="fa fa-edit" />
          </button>

          <button
            type="button"
            onClick={() => {
              handleEliminarCliente(row)
            }}
            className="btn btn-sm btn-danger ml-1"
            title="Eliminar"
          >
            <i className="fa fa-trash" />
          </button>

          <button
            type="button"
            className="btn btn-sm btn-success ml-1"
            title="Movimientos"
            onClick={() => {
              handleMovsCliente(row)
            }}
          >
            <i className="fa fa-money-bill"></i>
          </button>
        </>
      ),
    },
  ]
  const fetchClientes = () => {
    setLoading(true)
    fetchConToken("clientes", { filtro, size, page }, "POST")
      .then((resp) => resp.json())
      .then((body) => {
        setLoading(false)

        if (body.ok) {
          setClientes(body.clientes)
          setTotal(body.total)
        }

        dispatch(clientesFinishRefresh())
      })
  }

  const handleSizeChange = (currentRowsPerPage) => {
    setPage(1)
    setSize(currentRowsPerPage)
  }

  const handlePageChange = (currentPage) => {
    setPage(currentPage)
  }

  const handleSearch = () => {
    setPage(1)
    setSize(intialSize)
    fetchClientes()
  }

  useEffect(() => {
    fetchClientes()
  }, [])

  useEffect(() => {
    fetchClientes()
  }, [size])

  useEffect(() => {
    fetchClientes()
  }, [page])

  useEffect(() => {
    if (refreshList) {
      fetchClientes()
    }
  }, [refreshList])

  return (
    <>
      <SubHeader title={"Clientes"} />
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      {loading ? (
                        <button
                          disabled={true}
                          type="button"
                          className="btn btn-sm btn-link spinner spinner-dark spinner-right"
                        >
                          Espere un momento...
                        </button>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>

                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-sm btn-success font-weight-bold"
                      onClick={handleExportarClientes}
                    >
                      <i className="flaticon2-download" /> Exportar
                    </button>

                    <button
                      type="button"
                      className="btn btn-sm btn-info font-weight-bold ml-2"
                      onClick={handleNuevoCliente}
                    >
                      <i className="flaticon2-add" /> Nuevo Cliente
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group-sm">
                        <label htmlFor="filtro">Filtro</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={filtro}
                          onChange={setFiltersValue}
                          name="filtro"
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="form-group-sm">
                        <button
                          type="button"
                          onClick={handleSearch}
                          className="btn btn-sm btn-primary"
                          style={{ marginTop: "28px" }}
                        >
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <br></br>
                      <CustomTable
                        columns={columns}
                        data={clientes}
                        responsive={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ClientesForma />
      <ClientesDetalle />
      <ClientesMovimientos />
    </>
  )
}
