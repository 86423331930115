import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useForm} from "../../hooks/useForm";
import {useDispatch, useSelector} from "react-redux";
import {startChecking, startLogin} from "../../actions/auth";

const initState = {
    email: '',
    password: ''
}

export const LoginScreen = () => {

    const {checking} = useSelector(state => state.auth);
    const [formValues, setFormValues] = useForm(initState);
    const {email, password} = formValues;
    const [validEmail, setValidEmail] = useState(true);
    const [validPassword, setValidPassword] = useState(true);
    const [disabledButtons, setDisabledButtons] = useState(checking);
    const dispatch = useDispatch();

    const handleShowForm = (type) => {
        document.getElementById('login-signin').classList.remove('hide-form');
        document.getElementById('login-signin').classList.remove('show-form');
        document.getElementById('login-forgot').classList.remove('hide-form');
        document.getElementById('login-forgot').classList.remove('show-form');
        if (type === 'forgot') {
            document.getElementById('login-forgot').classList.add('show-form');
            document.getElementById('login-signin').classList.add('hide-form');
        } else {
            document.getElementById('login-signin').classList.add('show-form');
            document.getElementById('login-forgot').classList.add('hide-form');
        }
    }

    const handleSubmitLogin = (e) => {
        e.preventDefault();
        if (email.length > 2 && password.length > 0) {
            setValidEmail(true);
            setValidPassword(true);

            dispatch(startChecking());
            dispatch(startLogin(email, password));

        } else {
            if (email.length <= 2) {
                setValidEmail(false);
            }

            if (password.length <= 0) {
                setValidPassword(false);
            }
        }
    }

    useEffect(() => {
        setDisabledButtons(checking);
        if(checking)
        {
            document.getElementById('kt_login_signin_submit').classList.add('spinner');
            document.getElementById('kt_login_signin_submit').classList.add('spinner-white');
            document.getElementById('kt_login_signin_submit').classList.add('spinner-right');

        } else {
            document.getElementById('kt_login_signin_submit').classList.remove('spinner');
            document.getElementById('kt_login_signin_submit').classList.remove('spinner-white');
            document.getElementById('kt_login_signin_submit').classList.remove('spinner-right');
        }
    }, [checking]);

    return (
        <div className="d-flex flex-column flex-root" style={{height: '100%'}}>
            <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">

                <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                    <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                        <div className="login-form login-signin" id="login-signin">
                            <div className="text-center mb-10 mb-lg-20">
                                <img style={{width:'200px'}} src={`assets/media/logos/vertroue-logo.png`} alt=""/>
                                <p className="text-muted font-weight-bold">Ingrese su correo y contraseña</p>
                            </div>
                            <form method="post" className="form" noValidate="noValidate" id="kt_login_signin_form" onSubmit={handleSubmitLogin}>
                                <div className="form-group">
                                    <input
                                        className="form-control form-control-solid h-auto py-5 px-6"
                                        type="email"
                                        placeholder="Correo electrónico"
                                        name="email"
                                        autoComplete="off"
                                        onChange={setFormValues}
                                        required={true}
                                        value={email}
                                    />

                                    {
                                        !validEmail ?
                                            <div className="fv-plugins-message-container">
                                                <div data-field="email" data-validator="notEmpty" className="fv-help-block">Correo electrónico requerido</div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                <div className="form-group">
                                    <input
                                        className="form-control form-control-solid h-auto py-5 px-6"
                                        type="password"
                                        placeholder="Contraseña"
                                        name="password"
                                        autoComplete="off"
                                        onChange={setFormValues}
                                        required={true}
                                        value={password}
                                    />
                                    {
                                        !validPassword ?
                                            <div className="fv-plugins-message-container">
                                                <div data-field="password" data-validator="notEmpty" className="fv-help-block">Contraseña requerida</div>
                                            </div>
                                            : ''
                                    }
                                </div>
                                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                                    <button type="button" onClick={() => {
                                        handleShowForm('forgot')
                                    }}
                                            className="btn btn-link text-dark-50 text-hover-primary my-3 mr-2"
                                            disabled={disabledButtons}
                                            id="kt_login_forgot">¿Olvido su contraseña?
                                    </button>
                                    <button type="submit" id="kt_login_signin_submit" disabled={disabledButtons} className="btn btn-primary ">Ingresar</button>
                                </div>
                            </form>
                        </div>
                        <div className="login-form login-forgot" id="login-forgot">
                            <div className="text-center mb-10 mb-lg-20">
                                <h3 className="font-size-h1">¿Olvido su contraseña?</h3>
                                <p className="text-muted font-weight-bold">Ingrese su correo electrónico para recuperar su contraseña</p>
                            </div>
                            <form className="form" noValidate="novalidate" id="kt_login_forgot_form">
                                <div className="form-group">
                                    <input className="form-control form-control-solid h-auto py-5 px-6" type="email" placeholder="Correo electrónico" name="email" autoComplete="off"/>
                                </div>
                                <div className="form-group d-flex flex-wrap flex-center">
                                    <button type="button" id="kt_login_forgot_submit" className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">Recuperar contraseña</button>
                                    <button type="button" id="kt_login_forgot_cancel"
                                            onClick={() => handleShowForm('login')}
                                            className="btn btn-light-dark font-weight-bold px-9 py-4 my-3 mx-4">Cancelar
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}