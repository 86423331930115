import React, {useEffect, useState} from "react";
import {SubHeader} from "../../layout/partials/subheader/subheader-v1";
import DataTable from "react-data-table-component";
import ReactApexChart from "react-apexcharts";
import {fetchConToken} from "../../helpers/fetch";

const seriesInit = [];
const optionsInit = {
    chart: {
        height: 350,
        type: 'line',
        zoom: {
            enabled: false
        },
    },
    dataLabels: {
        enabled: false
    },
    stroke: {
        curve: 'straight'
    },
    grid: {
        row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        categories: [],
    },
    yaxis: {
        labels: {
            formatter: function (val, index){
                return numberFormat.format(val)
            }
        }
    }
};

const numberFormat = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'})

const columns = [
    {"name": "Año", "selector": "anio"},
    {"name": "Mes", "selector": "mesLabel"},
    {"name": "Gananacia", "selector": "ganancia", "right":true},
    {"name": "%", "selector": "porcentaje", "right":true},
];


export const DashboardClientes = () => {

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [series, setSeries] = useState(seriesInit);
    const [options, setOptions] = useState(optionsInit);
    const [cliente, setCliente] = useState('');
    const [inversion, setInversion] = useState('');
    const [ganancia, setGanancia] = useState('');
    const [total, setTotal] = useState('');
    const [porcentaje, setPorcentaje] = useState('');
    const [porcentajePromedio, setporcentajePromedio] = useState('');
    const [gananciaPromedio, setgananciaPromedio] = useState('');

    useEffect(() => {
        fetchConToken('dashboard/clientes', {}, 'POST')
            .then(resp => resp.json())
            .then(body => {
                if (body.ok) {

                    setLoading(false);
                    setData(body.operaciones);
                    setSeries(body.series);
                    setCliente(body.cliente.nombre);
                    setInversion(body.cliente.inversion);
                    setGanancia(body.ganancia);
                    setTotal(body.total);
                    setPorcentaje(body.porcentaje)
                    setgananciaPromedio(body.ganancia_promedio)
                    setporcentajePromedio(body.porcentaje_promedio)
                    setOptions({
                        ...options,
                        xaxis: {
                            ...options.xaxis,
                            categories: body.categorias
                        }
                    });
                }
            });
    }, []);

    if (loading) {
        return (<>
            <SubHeader title={"Dashboard"}/>
            <div className="d-flex flex-column-fluid">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            Loading...
                        </div>
                    </div>
                </div>
            </div>
        </>)
    }

    return (
        <>
            <SubHeader title={"Dashboard"}/>
            <div className="d-flex flex-column-fluid">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-custom gutter-b">
                                <div className="card-body">
                                    <table className="table table-sd table-bordered">
                                        <tbody>
                                        <tr>
                                            <th rowSpan={2}>Cliente</th>
                                            <td rowSpan={2}>{cliente}</td>
                                            <th>Ganancia acomulada</th>
                                            <td className="text-center">{ganancia}</td>
                                            <th rowSpan={4} style={{ textAlign:"center", verticalAlign:"middle" }}>Total</th>
                                            <td rowSpan={4} style={{ textAlign:"center", verticalAlign:"middle" }}>{total}</td>
                                        </tr>
                                        <tr>
                                            <th>% Ganancia</th>
                                            <td className="text-center">{porcentaje}</td>
                                        </tr>
                                        <tr>
                                            <th rowSpan={2}>Inversión</th>
                                            <td rowSpan={2} className="text-center">{inversion}</td>
                                            <th>Ganancia promedio</th>
                                            <td className="text-center">{gananciaPromedio}</td>
                                        </tr>
                                        <tr>
                                            <th>Porcentaje promedio</th>
                                            <td className="text-center">{porcentajePromedio}</td>
                                        </tr>
                                        </tbody>

                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12">

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-6">

                            <div className="card card-custom gutter-b">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">
                                            Operaciones
                                        </h3>
                                    </div>

                                </div>
                                <div className="card-body">

                                    <DataTable
                                        columns={columns}
                                        data={data}
                                        striped={true}
                                        dense={true}
                                        fixedHeader={true}
                                        fixedHeaderScrollHeight={"450px"}
                                    />


                                </div>
                            </div>
                        </div>

                        <div className="col-md-6">

                            <div className="card card-custom gutter-b">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label">
                                            Grafica
                                        </h3>
                                    </div>

                                </div>
                                <div className="card-body">
                                    <ReactApexChart options={options} series={series} type="line" height={350}/>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}