import React, { useEffect, useState } from "react"

export const CustomTable = (props) => {
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [responsive, setResponsive] = useState(true)

  useEffect(() => {
    setColumns(props.columns)
    setData(props.data)
    setResponsive(props.responsive)
  }, [props])

  return (
    <>
      <div className={responsive ? "table-responsive" : ""}>
        <table className="table table-bordered table-hover">
          <thead>
            <tr className="table-secondary">
              {columns.map((value, index) => {
                var classVal = value.right ? "text-right" : ""
                return (
                  <th key={index} className={classVal}>
                    {value.name}
                  </th>
                )
              })}
            </tr>
          </thead>
          <tbody>
            {data.map((value, index) => {
              return (
                <tr key={index}>
                  {columns.map((colVal, colIndex) => {
                    var o = colVal.selector
                    var classVal = colVal.right ? "text-right" : ""
                    classVal = colVal.center ? 'text-center' : classVal
                    var noWrap = colVal.nowrap ? "text-nowrap" : ""
                    var cell = colVal.cell ? colVal.cell : value[o]
                    var valCell =
                      cell instanceof Function ? cell({ cid: value.cid, activo: value.activo }) : cell


                    return (
                      <td className={classVal + " " + noWrap} key={colIndex}>
                        {valCell}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </>
  )
}
