import React, {useState} from "react";
import Modal from "react-modal";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "../../../hooks/useForm";
import Swal from "sweetalert2";
import moment from "moment";
import {operacionesClearActive, operacionesCloseForm, operacionesRefresh} from "../../../actions/operaciones";
import {fetchConToken, fetchConTokenAndFile} from "../../../helpers/fetch";
import Context from "react-redux/lib/components/Context";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '400px',
        minWidth: '400px',
        maxWidth: '99%'
    },
    overlay: {
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    }
};

const initialForm = {
    fecha: moment().format('YYYY-MM-DD'),
}

Modal.setAppElement('#root');

export const OperacionesForma = () => {

    const dispatch = useDispatch();
    const [formValues, handleInputChange] = useForm(initialForm);
    const {
        fecha
    } = formValues;


    const {formOpen} = useSelector(state => state.operaciones);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [archivoSeleccionado, setArchivoSeleccionado] = useState('');
    const [inputFileSelected, setInputFileSelected] = useState(null);


    const handleCloseModal = () => {
        dispatch(operacionesCloseForm());
        dispatch(operacionesClearActive());
    }

    const handleFileSelect = (e) => {
        setArchivoSeleccionado(e.target.files[0].name);
        setInputFileSelected(e.target.files[0]);
    }

    const handleClickFile = () => {
        document.querySelector('#fileSelector').click();
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if(!inputFileSelected)
        {
            Swal.fire('Warning', 'Seleccione el archivo que desea subir', 'warning');
            return false;
        }

        if(fecha === '')
        {
            Swal.fire('Warning', 'Seleccione la fecha', 'warning');
            return false;
        }

        const formData = new FormData();
        formData.append('fecha', fecha);
        formData.append('file', inputFileSelected);


        setDisabledButtons(true);

        let ruta = `operaciones/subir`;

        fetchConTokenAndFile(ruta, formData, 'POST')
            .then(resp => resp.json())
            .then(body => {
                setDisabledButtons(false);
                if (body.ok) {
                    Swal.fire('Guardado', 'Se ha guardado el usuario', 'success');
                    dispatch(operacionesCloseForm());
                    dispatch(operacionesRefresh());
                    dispatch(operacionesClearActive());
                } else {
                    Swal.fire('Error', body.msg, 'error');
                }
            });

    }

    return (
        <Modal
            isOpen={formOpen}
            style={customStyles}
            closeTimeoutMS={0}
            shouldCloseOnEsc={false}
        >
            <h6>Archivo de Operación</h6>
            <hr/>
            <div className=" ">
                <form method="post" onSubmit={handleSubmit} autoComplete="off">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="form-group">
                                <label htmlFor="fecha">Fecha *</label>
                                <input type="date" name="fecha" value={fecha} onChange={handleInputChange} className="form-control" required={true}/>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="archivo">Archivo *</label>
                                <button type="button" onClick={handleClickFile} className="btn btn-primary">Seleccionar Excel</button>
                                <input
                                    type="file"
                                    id="fileSelector"
                                    name="file"
                                    style={{display:'none'}}
                                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileSelect}/>
                                <div style={{height:'20px'}}>{archivoSeleccionado}</div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 text-right mt-2">
                            <button type="submit" className={`btn btn-success ${disabledButtons ? 'spinner spinner-white spinner-right' : ''}`} disabled={disabledButtons}>Guardar</button>
                            <button type="button" className="btn btn-default ml-1" onClick={handleCloseModal} disabled={disabledButtons}>Cancelar</button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    )
}