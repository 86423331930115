import React from "react";
import {Link} from "react-router-dom";
export const Footer = () => {
    return(

        <div className="footer bg-white py-4 d-flex flex-lg-column" id="kt_footer">

            <div className="container-fluid d-flex flex-column flex-md-row align-items-center justify-content-between">

                <div className="text-dark order-2 order-md-1">
                    <span className="text-muted font-weight-bold mr-2">2021©</span>
                    <Link to="/" className="text-dark-75 text-hover-primary">Vertroue</Link>
                </div>


                <div className="nav nav-dark">
                    <a href="http://uploading.mx/" rel="noreferrer" target="_blank" className="nav-link pl-0 pr-5">Designed by Uploading.mx</a>
                </div>

            </div>

        </div>

    );
}