import React from "react";
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {logout, startLogout} from "../../../../actions/auth";

export const User = () => {
    const dispatch = useDispatch();
    const handleLogOut = () => {
        localStorage.removeItem('token');
        dispatch(startLogout());
        dispatch(logout());
    }

    return (
        <>
            <div className="navi navi-spacer-x-0 pt-5">

                <Link to="/perfil" className="navi-item px-8">
                    <div className="navi-link">
                        <div className="navi-icon mr-2">
                            <i className="flaticon2-calendar-3 text-success"/>
                        </div>
                        <div className="navi-text">
                            <div className="font-weight-bold">Mi Perfil</div>
                            <div className="text-muted">Configuración de la cuenta</div>
                        </div>
                    </div>
                </Link>

                <div className="navi-separator mt-3"/>
                <div className="navi-footer px-8 py-5">
                    <button type="button" onClick={handleLogOut} className="btn btn-light-primary font-weight-bold">Salir</button>
                </div>

            </div>
        </>
    );
}