import {types} from "../types/types";

export const usuariosOpenForm = () => ({type: types.usuariosOpenForm});
export const usuariosCloseForm = () => ({type: types.usuariosCloseForm});

export const usuariosSetActive = (cid) => ({
   type: types.usuariosSetActive,
   payload: cid
});

export const usuariosClearActive = () => ({
   type: types.usuariosClearActive
});

export const usuariosRefresh = () => ({
   type: types.usuariosRefresh
});

export const usuariosFinishRefresh = () => ({
   type: types.usuariosFinishRefresh
});
