import React, {useEffect, useState} from "react"
import DataTable from "react-data-table-component"
import Swal from "sweetalert2"
import {SubHeader} from "../../../layout/partials/subheader/subheader-v1"
import {useForm} from "../../../hooks/useForm"
import {fetchConToken} from "../../../helpers/fetch"
import {useDispatch, useSelector} from "react-redux"
import {OperacionesForma} from "./OperacionesForma"
import {
  operacionesClearActive,
  operacionesFinishRefresh,
  operacionesOpenDetalle,
  operacionesOpenForm,
  operacionesRefresh,
  operacionesSetActive,
} from "../../../actions/operaciones"
import {OperacionesDetalle} from "./OperacionesDetalle"
import {CustomTable} from "../../customs/CustomTable"

const initFilters = {
  filtro: "",
}

const intialSize = 20

const defaultPaginationOptions = {
  rowsPerPageText: "Registros por página:",
  rangeSeparatorText: "de",
}

export const OperacionesScreen = () => {
  const dispatch = useDispatch()
  const {refreshList} = useSelector((state) => state.operaciones)

  const [filtersValue, setFiltersValue] = useForm(initFilters)
  const {filtro} = filtersValue
  const [loading, setLoading] = useState(false)
  const [operaciones, setOperaciones] = useState([])
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [size, setSize] = useState(intialSize)

  const handleVerOperacion = ({cid}) => {
    dispatch(operacionesSetActive(cid))
    dispatch(operacionesOpenDetalle())
  }

  const handleNuevoOperacion = () => {
    dispatch(operacionesClearActive())
    dispatch(operacionesOpenForm())
  }

  const handleEliminarOperacion = ({cid}) => {
    Swal.fire({
      title: "¿Desea eliminar el archivo de operaciones?",
      text: "Esta acción no se puede deshacer",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Si, eliminar!",
      cancelButtonText: "No, cancelar",
      closeOnConfirm: false,
      closeOnCancel: false,
      icon: "warning",
    }).then((result) => {
      if (result.isConfirmed) {
        fetchConToken(`operaciones/${cid}`, {}, "DELETE")
          .then((response) => response.json())
          .then((body) => {
            if (body.ok) {
              Swal.fire(
                "Eliminado",
                "El archivo de operaciones ha sido eliminado correctamente"
              )
              dispatch(operacionesRefresh())
            } else {
              Swal.fire("Error", body.msg, "error")
            }
          })
      }
    })
  }

  const columns = [
    {name: "#", selector: "id"},
    {name: "Fecha", selector: "fecha", minWidth: "100px"},
    {name: "Archivo", selector: "nombreArchivo", minWidth: "300px"},
    {name: "No. Movimientos", selector: "movimientos"},
    {name: "Estado", selector: "activoLabel"},
    {
      name: "Acciones",
      sorteable: false,
      cell: (row) => (
        <>
          <button
            type="button"
            onClick={() => {
              handleVerOperacion(row)
            }}
            className="btn btn-sm btn-default ml-1"
          >
            <i className="fa fa-search" />
          </button>
          <button
            type="button"
            onClick={() => {
              handleEliminarOperacion(row)
            }}
            className="btn btn-sm btn-danger ml-1"
          >
            <i className="fa fa-trash" />
          </button>
        </>
      ),
    },
  ]
  const fetchOperaciones = () => {
    setLoading(true)
    fetchConToken("operaciones", {filtro, size, page}, "POST")
      .then((resp) => resp.json())
      .then((body) => {
        setLoading(false)

        if (body.ok) {
          setOperaciones(body.operaciones)
          setTotal(body.total)
        }

        dispatch(operacionesFinishRefresh())
      })
  }

  const handleSizeChange = (currentRowsPerPage) => {
    setPage(1)
    setSize(currentRowsPerPage)
  }

  const handlePageChange = (currentPage) => {
    setPage(currentPage)
  }

  const handleSearch = () => {
    setPage(1)
    setSize(intialSize)
    fetchOperaciones()
  }

  useEffect(() => {
    fetchOperaciones()
  }, [])

  useEffect(() => {
    fetchOperaciones()
  }, [size])

  useEffect(() => {
    fetchOperaciones()
  }, [page])

  useEffect(() => {
    if (refreshList) {
      fetchOperaciones()
    }
  }, [refreshList])

  return (
    <>
      <SubHeader title={"Operaciones"} />
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">
                      {loading ? (
                        <button
                          disabled={true}
                          type="button"
                          className="btn btn-sm btn-link spinner spinner-dark spinner-right"
                        >
                          Espere un momento...
                        </button>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>

                  <div className="card-toolbar">
                    <button
                      type="button"
                      className="btn btn-sm btn-success font-weight-bold"
                      onClick={handleNuevoOperacion}
                    >
                      <i className="flaticon2-add" /> Agregar Operación
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group-sm">
                        <label htmlFor="filtro">Filtro</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          value={filtro}
                          onChange={setFiltersValue}
                          name="filtro"
                        />
                      </div>
                    </div>
                    <div className="col-md-1">
                      <div className="form-group-sm">
                        <button
                          type="button"
                          onClick={handleSearch}
                          className="btn btn-sm btn-primary"
                          style={{marginTop: "28px"}}
                        >
                          Buscar
                        </button>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <br />

                      <CustomTable
                        columns={columns}
                        data={operaciones}
                        responsive={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <OperacionesForma />
      <OperacionesDetalle />
    </>
  )
}
