import React from 'react';
import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';


export const PrivateRoute = ({isLogged, component: Component, ...rest }) => {

    return (
        <Route { ...rest }
               component={ (props) => (
                   ( isLogged )
                       ? ( <Component { ...props } /> )
                       : ( <Redirect to="/login" /> )
               )}

        />
    )
}

PrivateRoute.propTypes = {
    isLogged: PropTypes.bool.isRequired,
    component: PropTypes.func.isRequired
}
