import {combineReducers} from "redux";
import {authReducer} from "./authReducer";
import {clientesReducer} from "./clientesReducer";
import {usuariosReducer} from "./usuariosReducer";
import {operacionesReducer} from "./operacionesReducer";

export const rootReducers = combineReducers({
    auth: authReducer,
    clientes: clientesReducer,
    usuarios: usuariosReducer,
    operaciones: operacionesReducer
});