import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useDispatch, useSelector} from "react-redux";
import {default as ReactSelect, components} from "react-select";
import {usuariosClearActive, usuariosCloseForm, usuariosRefresh} from "../../../actions/usuarios";
import {fetchConToken} from "../../../helpers/fetch";
import {useForm} from "../../../hooks/useForm";
import Swal from "sweetalert2";

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '700px',
        minWidth: '400px',
        maxWidth: '99%'
    },
    overlay: {
        background: 'rgba(0,0,0,0.5)',
        zIndex: 1000
    }
};

const initialForm = {
    name: '',
    email: '',
    isActivo: 1,
    password: ''
}



Modal.setAppElement('#root');

export const UsuariosForma = () => {

    const dispatch = useDispatch();
    const [formValues, handleInputChange, handleInputChangeManual] = useForm(initialForm);
    const {
        name,
        email,
        isActivo,
        password,
    } = formValues;


    const {formOpen, activo} = useSelector(state => state.usuarios);
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [clientesLista, setClientesLista] = useState([]);
    const [clientes, setClientes] = useState([]);

    const handleCloseModal = () => {
        dispatch(usuariosCloseForm());
        dispatch(usuariosClearActive());
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const usuariosData = {
            ...formValues, clientes
        }

        setDisabledButtons(true);

        let ruta = activo ? `usuarios/${activo}` : 'usuarios';

        fetchConToken(ruta, usuariosData, 'PUT')
            .then(resp => resp.json())
            .then(body => {
                setDisabledButtons(false);
                if (body.ok) {
                    Swal.fire('Guardado', 'Se ha guardado el usuario', 'success');
                    dispatch(usuariosCloseForm());
                    dispatch(usuariosRefresh());
                    dispatch(usuariosClearActive());
                } else {
                    Swal.fire('Error', body.msg, 'error');
                }
            });

    }

    const getClientes = () => {
        return fetchConToken(`usuarios/clientes`, {}, 'POST')
            .then(resp => resp.json())
            .then(body => {
                if(body.ok){
                    setClientesLista(body.clientes);
                }
            });
    }

    useEffect(() => {
        if (activo) {
            fetchConToken(`usuarios/${activo}`)
                .then(resp => resp.json())
                .then(body => {
                    if (body.ok) {
                        handleInputChangeManual({
                            name: body.usuario.name,
                            email: body.usuario.email,
                            isActivo: body.usuario.active,
                            password: ''
                        });

                        setClientes(body.clientes);
                        //setClientesSeleccionado(body.seleccionados);
                    }
                })
        } else {
            handleInputChangeManual(initialForm);
            setClientes([]);
        }

    }, [activo]);

    useEffect(() => {
        if(formOpen){
            getClientes();
        }
    }, [formOpen])


    const Option = (props) => {
        return(
          <>
              <components.Option {...props}>
                  <input
                      type="checkbox"
                      checked={props.isSelected}
                      onChange={() => null}
                  />&nbsp;
                  <label>{props.label}</label>
              </components.Option>
          </>
        );
    }

    const handleChange = (selected) => {
        setClientes(selected);
    }

    return (
        <Modal
            isOpen={formOpen}
            style={customStyles}
            closeTimeoutMS={0}
            shouldCloseOnEsc={false}
        >
            <h6>Usuario</h6>
            <hr/>
            <form method="post" onSubmit={handleSubmit} autoComplete="off">
                <div className="row modalMaxHeight">
                    <div className="col-md-8">
                        <div className="form-group">
                            <label htmlFor="name">Nombre *</label>
                            <input type="text" name="name" value={name} onChange={handleInputChange} className="form-control" required={true}/>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="isActivo">Activo</label>
                            <select name="isActivo" value={isActivo} onChange={handleInputChange} className="form-control">
                                <option value="1">Si</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="isActivo">Clientes asociados</label>
                            <ReactSelect
                                isMulti
                                name="clientes"
                                closeOnMenuSelect={false}
                                options={clientesLista}
                                value={clientes}
                                components={{Option}}
                                hideSelectedOptions={false}
                                allowSelectAll={true}
                                onChange={handleChange}
                                menuPortalTarget={document.body}
                                styles={{ menuPortal: (base) => ({...base, zIndex: 9999,})}}
                            />

                        </div>
                    </div>

                    <div className="col-md-12"><h6>Datos de acceso al portal</h6>
                        <hr/>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="email">Correo *</label>
                            <input type="email" value={email} onChange={handleInputChange} name="email" className="form-control" required={true}/>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="form-group">
                            <label htmlFor="password">Contraseña {!activo && '*'}</label>
                            <input type="password" value={password} onChange={handleInputChange} name="password" className="form-control" required={!activo}/>
                            {activo && <small>Dejar en blanco si no desea cambiar la contraseña</small>}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6"><small>(*) Campos requeridos</small></div>
                    <div className="col-md-6 text-right mt-2">
                        <button type="submit" className={`btn btn-success ${disabledButtons ? 'spinner spinner-white spinner-right' : ''}`} disabled={disabledButtons}>Guardar</button>
                        <button type="button" className="btn btn-default ml-1" onClick={handleCloseModal} disabled={disabledButtons}>Cancelar</button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}