import {types} from "../types/types";

export const operacionesOpenForm = () => ({type: types.operacionesOpenForm});
export const operacionesCloseForm = () => ({type: types.operacionesCloseForm});

export const operacionesOpenDetalle = () => ({type: types.operacionesOpenDetalle});
export const operacionesCloseDetalle = () => ({type: types.operacionesCloseDetalle});

export const operacionesSetActive = (cid) => ({
    type: types.operacionesSetActive,
    payload: cid
});

export const operacionesClearActive = () => ({
    type: types.operacionesClearActive
});

export const operacionesRefresh = () => ({
    type: types.operacionesRefresh
});

export const operacionesFinishRefresh = () => ({
    type: types.operacionesFinishRefresh
});