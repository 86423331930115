import React, {useEffect, useState} from "react"
import {SubHeader} from "../../../layout/partials/subheader/subheader-v1"
import {fetchConToken} from "../../../helpers/fetch"
import DataTable from "react-data-table-component"
import ReactApexChart from "react-apexcharts"
import {CustomTable} from "../../customs/CustomTable"

const seriesInit = []
const optionsInit = {
  chart: {
    height: 350,
    type: "line",
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "straight",
  },
  grid: {
    row: {
      colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [],
  },
}

export const DashboardAdmin = () => {
  const [loading, setLoading] = useState(true)
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [series, setSeries] = useState(seriesInit)
  const [options, setOptions] = useState(optionsInit)

  useEffect(() => {
    fetchConToken("dashboard", {}, "POST")
      .then((resp) => resp.json())
      .then((body) => {
        if (body.ok) {
          setLoading(false)
          setColumns(body.columns)
          setData(body.data)
          setSeries(body.series)
          setOptions({
            ...options,
            xaxis: {
              ...options.xaxis,
              categories: body.categories,
            },
          })
        }
      })
  }, [])

  if (loading) {
    return (
      <>
        <SubHeader title={"Dashboard"} />
        <div className="d-flex flex-column-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">Loading...</div>
            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <>
      <SubHeader title={"Dashboard"} />
      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Operaciones</h3>
                  </div>
                </div>
                <div className="card-body">
                  <CustomTable
                    columns={columns}
                    data={data}
                    responsive={true}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Grafica</h3>
                  </div>
                </div>
                <div className="card-body">
                  <ReactApexChart
                    options={options}
                    series={series}
                    type="line"
                    height={350}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
