import {types} from "../types/types";

const initState = {
    refreshList: false,
    formOpen: false,
    activo: null,
    formOpenDetalle: false
};

export const operacionesReducer = (state = initState, action) => {
    switch (action.type) {
        case types.operacionesOpenForm:
            return {
                ...state,
                formOpen: true
            }

        case types.operacionesCloseForm:
            return {
                ...state,
                formOpen: false
            }

        case types.operacionesSetActive:
            return {
                ...state,
                activo: action.payload
            }

        case types.operacionesClearActive:
            return {
                ...state,
                activo: null
            }

        case types.operacionesRefresh:
            return {
                ...state,
                refreshList: true
            }

        case types.operacionesFinishRefresh:
            return {
                ...state,
                refreshList: false
            }

        case types.operacionesOpenDetalle:
            return {
                ...state,
                formOpenDetalle: true
            }

        case types.operacionesCloseDetalle:
            return {
                ...state,
                formOpenDetalle: false
            }

        default:
            return state;
    }
}
