import React, {useEffect, useState} from "react"
import {SubHeader} from "../../../layout/partials/subheader/subheader-v1"
import DataTable from "react-data-table-component"
import {fetchConToken} from "../../../helpers/fetch"
import {clientesOpenDetail, clientesSetActive} from "../../../actions/clientes"
import {useDispatch} from "react-redux"
import {ClientesDetalle} from "../clientes/ClientesDetalle"
import {CustomTable} from "../../customs/CustomTable"

export const ReportesScreen = () => {
  const dispatch = useDispatch()
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])

  const [columnsMinMax, setColumnsMinMax] = useState([])
  const [dataMinMax, setDataMinMax] = useState([])

  const [columnsPromedio, setColumnsPromedio] = useState([])
  const [dataPromedio, setDataPromedio] = useState([])

  const [loading, setLoading] = useState(true)

  const handleVerCliente = ({cid}) => {
    dispatch(clientesSetActive(cid))
    dispatch(clientesOpenDetail())
  }

  useEffect(() => {
    fetchConToken("reportes", {}, "GET")
      .then((resp) => resp.json())
      .then((body) => {
        if (body.ok) {
          setLoading(false)
          setColumns(body.columns)
          setData(body.data)

          setColumnsMinMax([
            {
              name: "",
              sorteable: false,
              cell: (row) => (
                <>
                  <button
                    type="button"
                    onClick={() => {
                      handleVerCliente(row)
                    }}
                    className="btn btn-sm btn-default ml-1"
                  >
                    <i className="fa fa-search" />
                  </button>
                </>
              ),
            },
            ...body.columnsMinMax,
          ])
          setDataMinMax(body.dataMinMax)

          setColumnsPromedio(body.columnsPromedio)
          setDataPromedio(body.dataPromedio)
        }
      })
  }, [])

  if (loading) {
    return (
      <>
        <SubHeader title={"Dashboard"} />
        <div className="d-flex flex-column-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">Loading...</div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <SubHeader title={"Reportes"} />

      <div className="d-flex flex-column-fluid">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Ganancias por mes</h3>
                  </div>
                </div>
                <div className="card-body">
                  <CustomTable
                    columns={columns}
                    data={data}
                    responsive={true}
                  />
                </div>
              </div>
            </div>

            <div className="col-md-12">
              <div className="card card-custom gutter-b">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label">Detalle por cliente</h3>
                  </div>
                </div>
                <div className="card-body">
                  <CustomTable
                    columns={columnsMinMax}
                    data={dataMinMax}
                    responsive={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ClientesDetalle />
    </>
  )
}
