export const types = {
  authLogin: "[AUTH] Login",
  authStartChecking: "[AUTH] Start checking",
  authEndChecking: "[AUTH] End checking",
  authStartCheckingToken: "[AUTH] Start checking token",
  authEndCheckingToken: "[AUTH] End checking token",
  authLogout: "[AUTH] Logout",

  clientesOpenForm: "[CLIENTES] Open form",
  clientesCloseForm: "[CLIENTES] Close form",
  clientesSetActive: "[CLIENTES] Set Active",
  clientesClearActive: "[CLIENTES] Clear active",
  clientesRefresh: "[CLIENTES] Update List",
  clientesFinishRefresh: "[CLIENTES] Finish Update List",
  clientesOpenDetail: "[CLIENTES] Open Detail",
  clientesCloseDetail: "[CLIENTES] Close Detail",
  clientesOpenMovs: "[CLIENTES] Open Movs",
  clientesCloseMovs: "[CLIENTES] Close Movs",

  usuariosOpenForm: "[USUARIOS] Open form",
  usuariosCloseForm: "[USUARIOS] Close form",
  usuariosSetActive: "[USUARIOS] Set Active",
  usuariosClearActive: "[USUARIOS] Clear active",
  usuariosRefresh: "[USUARIOS] Update List",
  usuariosFinishRefresh: "[USUARIOS] Finish Update List",

  operacionesOpenForm: "[OPERACIONES] Open form",
  operacionesCloseForm: "[OPERACIONES] Close form",
  operacionesSetActive: "[OPERACIONES] Set Active",
  operacionesClearActive: "[OPERACIONES] Clear active",
  operacionesRefresh: "[OPERACIONES] Update List",
  operacionesFinishRefresh: "[OPERACIONES] Finish Update List",
  operacionesOpenDetalle: "[OPERACIONES] Open detalle",
  operacionesCloseDetalle: "[OPERACIONES] Close detalle",
};
